import React, { useState } from 'react';
import cx from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/hooks';
import { ArrowIcon } from 'icons';
import { getChainNameById } from 'constants/community';
import {
  removeCommunityTPCsById,
  updateTpc,
} from 'store/community/actionCreators';
import TPCForm from 'components/TPCForm';
import RoleName from 'components/RoleName';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal';
import NotFound from 'components/NotFound';
import fonts from 'styles/typography.module.scss';
import s from './EditTpc.module.scss';
import { DiscordToken } from 'custom-types';
import UpgradePlanModal from '../UpgradePlanModal';
import useGetCurrentCommunity from '../../hooks/useGetCurrentCommunity';
import useCheckTGR from '../../hooks/useCheckTGR';

const EditTpc: React.FC = () => {
  const {
    communityId: activeCommunityId,
    roleId: activeRoleId,
    tpcId: activeTpcSk,
  } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const communitiesFromState = useSelector(
    (state) => state.community.communityGroups.items,
  );
  const loading = useSelector((state) => state.community.loading);
  const platform = useSelector((state) => state.user.platform);
  const communityCheck = useGetCurrentCommunity();
  const { errorMessage, setErrorMessage, isLoading } = useCheckTGR(
    `You need to upgrade to manage any exceeded ${
      platform === 'discord' ? 'TGRs' : 'TGAs'
    }.`,
  );
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { community: activeCommunity } = communityCheck;
  const communityFromState = communitiesFromState.find(
    (community) => community.communityId === activeCommunityId,
  )!;
  const activeRole = communityFromState.roles.find(
    (role) => role.id === activeRoleId,
  )!;
  const activeTpc = communityFromState.tpcs.find(
    (tpc) => tpc.sk === `TPC#${activeTpcSk}`,
  );

  const submitHandler = (data: DiscordToken) => {
    if (errorMessage) {
      setShowUpgradeModal(true);
      return;
    }
    if (!activeTpc) return;
    dispatch(
      updateTpc(
        communityFromState.pk,
        activeTpc.id,
        `TPC#${activeTpcSk}`,
        {
          ...data,
          groupId: activeTpc.groupId,
          roleId: activeRoleId ?? activeTpc.roleId,
        },
        () => navigate(`../..`, { relative: 'path' }),
      ),
    );
  };

  if (!activeTpc) {
    return <NotFound title="Rule not found" />;
  }
  return (
    <>
      <main className={s.wrapper}>
        {!isLoading && (
          <>
            <div className={s.content}>
              {showUpgradeModal && errorMessage && (
                <UpgradePlanModal
                  title={`${
                    platform === 'discord' ? 'TGR' : 'TGA'
                  } Allowance Exceeded`}
                  message={errorMessage}
                  handleClose={() => {
                    setErrorMessage(undefined);
                    navigate(-1);
                  }}
                  handleSubmit={() => {
                    setErrorMessage(undefined);
                    navigate(
                      `/dashboard/${activeCommunity?.communityId}/subscription`,
                    );
                  }}
                ></UpgradePlanModal>
              )}
              {/* <img src={createTPCBG} alt="bg img" className={s.bg} /> */}
              <div className={s.head}>
                <button
                  className={cx(fonts.button1, s.backButton)}
                  type="button"
                  onClick={() => navigate(`../..`, { relative: 'path' })}
                  data-tracking-info={JSON.stringify({
                    id:
                      platform === 'discord'
                        ? `button:back-to-tgrs:click`
                        : `button:back-to-tgas:click`,
                    server_id: activeCommunityId,
                    chain_type: activeTpc.chainId.toString(),
                    token_type: activeTpc.type,
                  })}
                >
                  <ArrowIcon className={s.arrow} title="back icon" />
                  {platform === 'discord'
                    ? `Back to ${activeRole.name} role`
                    : `Back to TGAs`}
                </button>
                <div className={s.headRow}>
                  {platform === 'discord' && (
                    <RoleName
                      className={fonts.title5}
                      roleColor={activeRole.color}
                    >
                      {activeRole.name}
                    </RoleName>
                  )}
                  <Button
                    color="secondary"
                    className={cx(fonts.button1, s.headButton)}
                    onClick={() => setShowConfirmationModal(true)}
                  >
                    Delete
                  </Button>
                </div>
              </div>
              <div className={s.formWrapper}>
                {loading && (
                  <div className={s.formLoading}>
                    <Spinner />
                  </div>
                )}
                <TPCForm
                  key={activeTpcSk}
                  onSubmit={submitHandler}
                  callback={() => null}
                  initialValue={{
                    key: Date.now(),
                    name: activeTpc.name,
                    minAmount: activeTpc.minToken,
                    maxAmount: activeTpc.maxToken,
                    minFlowRate: activeTpc?.minFlowRate || '',
                    streamReceiver: activeTpc?.streamReceiver || '',
                    address: activeTpc.contractAddress || null,
                    filter: activeTpc.filter || null,
                    currency: activeTpc.currency || null,
                    taxon: activeTpc.taxon || null,
                    raftTokenId: activeTpc?.raftTokenId,
                    otterspaceName: activeTpc?.otterspaceName,
                    chainType: activeTpc.chainId.toString(),
                    tokenType: activeTpc.type || null,
                    roleId: activeTpc.roleId || undefined,
                    traits: activeTpc.traits,
                    traitsId: activeTpc.traitsId || '',
                    traitsCondition: activeTpc.traitsCondition || 'and',
                    tokenSymbol: activeTpc.tokenSymbol ?? '',
                    collectionName: activeTpc.collectionName ?? '',
                  }}
                />
              </div>
            </div>
            <aside className={s.aside}>
              <h5 className={cx(fonts.title3, s.asideTitle)}>
                {platform === 'discord' ? 'TGRs' : 'TGAs'}
              </h5>
              <div>
                {platform === 'telegram' && (
                  <button
                    className={cx(s.tpcItem)}
                    type="button"
                    onClick={() =>
                      navigate(
                        `/dashboard/${activeCommunityId}/tgas/add?groupId=${activeTpc?.groupId}`,
                      )
                    }
                  >
                    + AND
                  </button>
                )}
                {communityFromState.tpcs
                  .filter(
                    (item) =>
                      item.roleId === activeRoleId ||
                      item.groupId === activeTpc.groupId,
                  )
                  .map((tpc, index) => {
                    const isActive = tpc.sk === `TPC#${activeTpcSk}`;
                    return (
                      <>
                        {index !== 0 && platform === 'telegram' && (
                          <p className={cx(fonts.title4, s.andOperator)}>AND</p>
                        )}
                        <button
                          key={tpc.sk}
                          className={cx(s.tpcItem, {
                            [s.tpcItemActive]: isActive,
                          })}
                          type="button"
                          onClick={() =>
                            platform === 'discord'
                              ? navigate(
                                  `/dashboard/${activeCommunityId}/tgrs/${activeRoleId}/edit/${encodeURIComponent(
                                    tpc.sk!.replace('TPC#', ''),
                                  )}`,
                                )
                              : navigate(
                                  `/dashboard/${activeCommunityId}/tgas/edit/${encodeURIComponent(
                                    tpc.sk!.replace('TPC#', ''),
                                  )}`,
                                )
                          }
                        >
                          <div className={s.tpcItemRow}>
                            <p className={cx(s.tpcItemKey, fonts.caption1)}>
                              Description
                            </p>
                            <p className={cx(s.tpcItemValue, fonts.caption1)}>
                              {tpc.name}
                            </p>
                          </div>
                          <div className={s.tpcItemRow}>
                            <p className={cx(s.tpcItemKey, fonts.caption1)}>
                              Chain type
                            </p>
                            <p className={cx(s.tpcItemValue, fonts.caption1)}>
                              {getChainNameById(tpc.chainId.toString())}
                            </p>
                          </div>
                          <div className={s.tpcItemRow}>
                            <p className={cx(s.tpcItemKey, fonts.caption1)}>
                              Token type
                            </p>
                            <p className={cx(s.tpcItemValue, fonts.caption1)}>
                              {tpc.type}
                            </p>
                          </div>
                        </button>
                      </>
                    );
                  })}
              </div>
            </aside>
            {showConfirmationModal && (
              <DeleteConfirmationModal
                tpcName={activeTpc.name}
                close={() => setShowConfirmationModal(false)}
                callback={() => {
                  setShowConfirmationModal(false);
                  dispatch(
                    removeCommunityTPCsById(
                      communityFromState.pk,
                      activeTpc.sk!,
                      () => navigate(`../..`, { relative: 'path' }),
                    ),
                  );
                }}
              />
            )}
          </>
        )}
      </main>
    </>
  );
};

export default EditTpc;
